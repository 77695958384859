import { Pagination } from "../types/pagination";
import {
  foodItemsPayload,
  FoodOrderMenu,
  PaymentLink,
  PriceBreakdown,
  RentalCancellationOption,
  RentalCancellationReasons,
  RentalCategoryChargeList,
  RentalChargeMaster,
  RentalPayment,
  RentalTripPayment,
  RentalVendorDetails,
  RequestServiceStats,
  RequestServiceSummary,
  // VersionDetails,
  VersionHistoryResponse,
} from "../types/rental-payment";
import {
  Charge,
  RentalTripRequestItem,
  RentalTripRequests,
  RentalTripReservations,
  Reservation,
  TripReservationItem,
} from "../types/rental-reservation";
import { IRentalTripPaymentService } from "../types/services/rental-trip-payment-service";
import { DELETE, FetchResult, GET, PATCH, POST } from "../utils/fetch";

class RentalTripPaymentService implements IRentalTripPaymentService {
  async getTripPayment(
    opportunity_slug: string,
  ): Promise<FetchResult<RentalTripPayment>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/payments`,
      { error, response } = await GET<PaymentResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: (RentalPayment | PaymentLink)[] = response.payment.map(
        (each) => {
          const {
              id,
              amount,
              email,
              external_id,
              payment_date,
              gateway,
              country_code,
              mobile,
              name,
              status,
              // transaction_id,
              url,
              payment_for,
              currency_symbol,
              currency_id,
            } = each,
            phoneNo = [country_code || "", mobile || ""].join(" ");

          if (url) {
            const formatted: PaymentLink = {
              id,
              name,
              email,
              phone_no: mobile,
              amount: amount.toLocaleString(),
              url,
              status: status,
              currency_symbol: currency_symbol || "",
              currency_id: currency_id || 1,
              payment_for: payment_for,
            };

            return formatted;
          }

          const formatted: RentalPayment = {
            id: id,
            name: name,
            email: email,
            phone_no: phoneNo,
            amount: amount || 0,
            payment_method: gateway,
            reference_no: external_id,
            payment_date: payment_date,
            payment_for: payment_for,
            status: status,
            currency_symbol: currency_symbol || "",
            currency_id: currency_id || 1,
          };

          return formatted;
        },
      ),
      net_paid_amount = response.net_paid_amount || 0,
      total_paid_amount = response.total_paid_amount || 0,
      total_reservations_amount = response.total_reservations_amount || 0,
      total_charges_amount = response.total_charges_amount || 0,
      outstanding = response.outstanding_amount || 0,
      credit_balance = response.credits_available || 0,
      is_international = response.is_international || false,
      loyalty_points_balance = response.loyalty_points_available || 0;

    return {
      error: null,
      response: {
        net_paid_amount: net_paid_amount,
        total_paid_amount: total_paid_amount,
        total_reservations_amount: total_reservations_amount,
        total_charges_amount: total_charges_amount,
        payments: formatted,
        outstanding_amount: outstanding,
        credit_balance: credit_balance,
        is_international: is_international,
        loyalty_points_balance: loyalty_points_balance,
      },
    };
  }

  async addPayment(
    opportunity_slug: string,
    name: string,
    payment_method: string,
    currency: string,
    amount: string,
    reference_no: string,
    payment_for: string,
    payment_date?: string,
    email?: string,
    phone_no?: string,
  ): Promise<FetchResult<null>> {
    const payload: any = {
      name,
      gateway: payment_method,
      currency_id: currency,
      amount: amount,
      external_id: reference_no,
      payment_for: payment_for,
      payment_date: payment_date,
    };

    if (email !== undefined) {
      payload.email = email;
    }

    if (phone_no !== undefined) {
      const [code, phoneNo] = phone_no.split(" ");

      payload.country_code = code;

      payload.mobile = phoneNo;
    }

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/payments`,
      { error, response } = await POST<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async addPaymentLink(
    opportunity_slug: string,
    amount: string,
    payment_for: string,
    name?: string,
    email?: string,
    phone_no?: string,
    description?: string,
  ): Promise<FetchResult<null>> {
    const payload: any = { amount, payment_for };

    if (name !== undefined) {
      payload.name = name;
    }

    if (email !== undefined) {
      payload.email = email;
    }

    if (phone_no !== undefined) {
      const [code, phoneNo] = phone_no.split(" ");

      payload.country_code = code;

      payload.mobile = phoneNo;
    }

    if (description !== undefined) {
      payload.description = description;
    }

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/payments/link`,
      { error, response } = await POST<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deletePayment(
    opportunity_slug: string,
    payment_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/payments/${payment_id}`,
      { error } = await DELETE<null>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async approvePayment(
    opportunity_slug: string,
    payment_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/payments/${payment_id}`,
      payload = {
        approved: true,
      },
      { error } = await PATCH<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async checkPaymentStatus(
    opportunity_slug: string,
    payment_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/payments/${payment_id}/status`,
      { error } = await POST<null>(url, {});

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updatePayment(
    opportunity_slug: string,
    payment_id: number,
    amount: string,
    reference_no: string,
    payment_method: string,
    payment_for: string,
    currency: string,
    payment_date?: string,
    name?: string,
    email?: string,
    phone_no?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      name,
      gateway: payment_method,
      currency_id: currency,
      amount: amount,
      external_id: reference_no,
      payment_for: payment_for,
      payment_date: payment_date,
    };

    if (email !== undefined) {
      payload.email = email;
    }

    if (phone_no !== undefined) {
      const [code, phoneNo] = phone_no.split(" ");

      payload.country_code = code;

      payload.mobile = phoneNo;
    }

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/payments/${payment_id}`,
      { error, response } = await PATCH<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getReservations(
    opportunity_slug: string,
  ): Promise<FetchResult<RentalTripReservations>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/reservations`,
      { error, response } = await GET<ReservationsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    let discountSum = 0,
      bookedPartially = false;

    const reservations = response.reservations.map((each) => {
        const {
            adults,
            children,
            breakdown: rawBreakdown,
            check_in,
            check_out,
            check_in_details,
            property,
            remote_status,
            slug,
            status,
            amount,
            removable,
            force_bookable,
            cancellable,
            coupon,
            hold_id,
            holdable,
            booking_approval_required,
            display_generate_pi,
            is_covered,
            is_opted,
            display_no_show,
            no_show,
            is_marketing_nights_opted,
            food_enabled,
          } = each,
          {
            average_base_rate,
            average_adults_rate,
            average_children_rate,
            gross_base_rate,
            total_taxes,
            convenience_fee,
            deposit,
            discount,
            discount_type,
            discount_value,
            total_commission,
            tds,
            caq,
            ivpl_budget,
            currency_symbol,
            points_redeemed,
            premium,
            extra_adults,
            agent_cutting_commission,
            preferred_rate,
          } = rawBreakdown || ({} as any);

        discountSum += discount;
        bookedPartially =
          bookedPartially || new Set(["sold_out", "force_booked"]).has(status);

        const breakdown: PriceBreakdown = {
            average_base_rate: average_base_rate || 0,
            average_adults_rate: average_adults_rate || 0,
            average_children_rate: average_children_rate || 0,
            gross_base_rate: gross_base_rate || 0,
            taxes: total_taxes || 0,
            convenience_fee: convenience_fee || 0,
            deposit: deposit || 0,
            commission: total_commission || 0,
            discount: discount || 0,
            discount_type: discount_type || undefined,
            discount_value: discount_value || undefined,
            tds: tds || undefined,
            acquisition_cost: caq || undefined,
            ivpl_budget: ivpl_budget || undefined,
            currency_symbol: currency_symbol || "",
            points_redeemed: points_redeemed || 0,
            premium: premium || undefined,
            extra_adults: extra_adults || 0,
            agent_cutting_commission: agent_cutting_commission || false,
            preferred_rate: preferred_rate || 0,
          },
          reservation: Reservation = {
            slug,
            food_enabled: food_enabled,
            status,
            remote_status,
            adults: adults || 0,
            children: children || 0,
            checkin_date: check_in,
            checkout_date: check_out,
            breakdown,
            check_in_details,
            property,
            amount,
            removable: removable || false,
            force_bookable: force_bookable || false,
            cancelable: cancellable || false,
            hold_id: hold_id || undefined,
            holdable: holdable,
            paid_amount: response.net_paid_amount || 0,
            booking_approval_required: booking_approval_required,
            display_generate_pi: display_generate_pi,
            is_refundable_opted: is_opted || false,
            is_refundable_covered: is_covered || false,
            display_no_show: display_no_show || false,
            no_show: no_show || false,
            is_marketing_nights_opted: is_marketing_nights_opted || false,
          };

        if (children) {
          reservation.children = children;
        }

        if (coupon) {
          const {
              id,
              name,
              code,
              active,
              applicable_to,
              applicable_from,
              visible,
              check_in_date,
              check_out_date,
              max_discount,
              min_amount,
              auto_apply,
              prebooking_duration,
              prebooking_value,
              location_ids,
              property_ids,
              coupon_offers,
              min_nights,
              tax_on_gross_amount,
            } = coupon,
            firstOffer = coupon_offers?.length ? coupon_offers[0] : null;
          reservation.coupon = {
            id: id,
            name: name || "",
            offer_id: firstOffer?.id || undefined,
            type: firstOffer?.offer_type || "",
            value: firstOffer?.value || 0,
            code: code,
            applicable_from_date: applicable_from || "",
            applicable_until_date: applicable_to || "",
            check_in_date: check_in_date,
            check_out_date: check_out_date,
            locations: location_ids || [],
            properties: property_ids || [],
            max_discount_amount: max_discount || 0,
            min_order_amount: min_amount || 0,
            min_booked_nights: min_nights || 0,
            early_bird_duration_unit: prebooking_duration || "",
            early_bird_duration_value: prebooking_value || 0,
            active: active || false,
            visible_on_website: visible || false,
            auto_applicable_on_website: auto_apply || false,
            tax_on_gross_amount: tax_on_gross_amount || false,
          };
        }

        return reservation;
      }),
      charges: Charge[] = response.charges.map((each) => {
        const {
            id,
            description,
            charge_type,
            amount,
            gst_percentage,
            vendor_id,
            charged_at,
            quantity,
            payment_status,
            payment_mode,
            // rental_property_id,
            rental_reservation_id,
            note,
            breakdown: {
              base_amount = 0,
              convenience_fee = 0,
              tax_on_base_amount = 0,
              tax_on_convenience_fee = 0,
              currency_symbol = "",
            } = {
              base_amount: 0,
              convenience_fee: 0,
              tax_on_base_amount: 0,
              tax_on_convenience_fee: 0,
              currency_symbol: "",
            },
            documents,
            show_cta,
          } = each,
          document_id = documents.length ? documents[0].id : undefined,
          document = documents.length ? documents[0].link : undefined,
          charge: Charge = {
            id: id || -1,
            description: description || "",
            charge_type: charge_type || "",
            amount: amount || 0,
            quantity: quantity || 0,
            gst_percentage: gst_percentage || "0",
            vendor: vendor_id || -1,
            charged_date: charged_at || "",
            paid: payment_status || "",
            payment_mode: payment_mode || "",
            rental_property_id: rental_reservation_id || "",
            note: note || "",
            breakdown: {
              average_base_rate: base_amount || 0,
              gross_base_rate: base_amount || 0,
              taxes: tax_on_base_amount || 0,
              convenience_fee:
                (convenience_fee || 0) + (tax_on_convenience_fee || 0),
              deposit: 0,
              commission: 0,
              discount: 0,
              currency_symbol: currency_symbol || "",
            },
            document_id: document_id || undefined,
            document: document || "",
            show_cta: show_cta,
            breakfast_item: [],
          };

        return charge;
      }),
      total = response.total,
      net_paid_amount = response.net_paid_amount || 0,
      total_paid_amount = response.total_paid_amount || 0,
      total_reservations_amount = response.total_reservations_amount || 0,
      reservation_currency = response.reservation_currency || "",
      total_charges_amount = response.total_charges_amount || 0,
      tax_invoice_charges_created =
        response.tax_invoice_charges_created || false,
      total_amount_breakup = response.total_amount_breakup;

    return {
      error: null,
      response: {
        total: total,
        net_paid_amount: net_paid_amount,
        total_paid_amount: total_paid_amount,
        total_reservations_amount: total_reservations_amount,
        total_charges_amount: total_charges_amount,
        tax_invoice_charges_created: tax_invoice_charges_created,
        discount: discountSum,
        booked_partially: bookedPartially,
        reservations: [...reservations, ...charges],
        reservation_currency: reservation_currency,
        total_amount_breakup: total_amount_breakup,
      },
    };
  }

  async removeDiscount(opportunity_slug: string): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/reservations/multi_discount`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async addCharge(
    opportunity_slug: string,
    category: string,
    charge_type: string,
    description: string,
    quantity: number,
    amount: string,
    gst_percentage: number,
    vendor: string,
    charged_date: string,
    rental_reservation_id: string,
    note: string,
    entity_type: string,
    vendor_radio_value?: string,
    vendor_type?: string,
    vendor_amount?: number,
    vendor_percentage?: number,
    document?: File | string,
    discount_type?: string,
    discount_code?: string,
    discount_value_percentage?: number,
    discount_value_flat?: number,
    cost_center_radio_value?: string,
    ivpl_company_budget?: number,
    ihpl_company_budget?: number,
    ivpl_reason_for_allocation?: string,
    ihpl_reason_for_allocation?: string,
    finalItemId?: string[],
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    payload.set("charge_master_category", category);
    payload.set("charge_type", charge_type);
    payload.set("description", description);
    payload.set("quantity", quantity.toString());
    payload.set("amount", amount.toString());
    payload.set("gst_percentage", gst_percentage.toString());
    payload.set("charged_at", charged_date);
    payload.set("rental_reservation_id", rental_reservation_id);
    payload.set("note", note);
    payload.set("status", "availed");
    payload.set("entity_type", entity_type);

    if (Number(vendor) > 0) {
      payload.set("vendor_id", vendor);
    }

    if (Number(vendor) > 0 && vendor_radio_value) {
      payload.set("vendor_payment_type", vendor_radio_value);
    }

    if (Number(vendor) > 0 && vendor_type) {
      payload.set("vendor_commission_type", vendor_type);
    }

    if (Number(vendor) > 0 && vendor_type === "flat" && vendor_amount) {
      payload.set("vendor_lohono_commission", vendor_amount.toString());
    }

    if (
      Number(vendor) > 0 &&
      vendor_type === "percentage" &&
      vendor_percentage
    ) {
      payload.set("vendor_lohono_commission", vendor_percentage.toString());
    }

    if (discount_type !== undefined) {
      payload.set("discount_type", discount_type);
    }

    if (discount_type !== undefined && discount_code !== undefined) {
      if (discount_code.length > 0) {
        payload.set("discount_value", discount_code);
      }
    }

    if (
      discount_type !== undefined &&
      discount_value_percentage !== undefined
    ) {
      if (discount_value_percentage > 0) {
        payload.set("discount_value", discount_value_percentage.toString());
      }
    }

    if (discount_type !== undefined && discount_value_flat !== undefined) {
      if (discount_value_flat > 0) {
        payload.set("discount_value", discount_value_flat.toString());
      }
    }

    if (cost_center_radio_value !== undefined) {
      payload.set("selected_cost_center", cost_center_radio_value);
    }

    if (
      cost_center_radio_value === "ivpl" &&
      ivpl_company_budget !== undefined
    ) {
      payload.set("ivpl_budget_amount", ivpl_company_budget.toString());
    }

    if (
      cost_center_radio_value === "ihpl" &&
      ihpl_company_budget !== undefined
    ) {
      payload.set("ihpl_budget_amount", ihpl_company_budget.toString());
    }

    if (
      cost_center_radio_value === "ivpl" &&
      ivpl_reason_for_allocation !== undefined
    ) {
      payload.set("reason_for_allocation", ivpl_reason_for_allocation);
    }

    if (
      cost_center_radio_value === "ihpl" &&
      ihpl_reason_for_allocation !== undefined
    ) {
      payload.set("reason_for_allocation", ihpl_reason_for_allocation);
    }

    if (finalItemId) {
      finalItemId.forEach((each, i) => {
        payload.set(`charge_items_attributes[${i}][item_id]`, each);
      });
    }

    if (document instanceof File) {
      payload.set("documents_attributes[][document_type]", "bill");
      payload.set("documents_attributes[][document_name]", "bill");
      payload.set("documents_attributes[][document_identifier]", "bill");
      payload.set("documents_attributes[][file]", document);
    }

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/charges`,
      { error, response } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: response,
    };
  }

  async updateCharge(
    opportunity_slug: string,
    category: string,
    charge_id: number,
    charge_type?: string,
    description?: string,
    quantity?: number,
    amount?: string,
    gst_percentage?: number,
    vendor?: string,
    charged_date?: string,
    rental_reservation_id?: string,
    note?: string,
    entity_type?: string,
    vendor_radio_value?: string,
    vendor_type?: string,
    vendor_amount?: number,
    vendor_percentage?: number,
    document_id?: number,
    document?: File | string,
    discount_type?: string,
    discount_code?: string,
    discount_value_percentage?: number,
    discount_value_flat?: number,
    cost_center_radio_value?: string,
    ivpl_company_budget?: number,
    ihpl_company_budget?: number,
    ivpl_reason_for_allocation?: string,
    ihpl_reason_for_allocation?: string,
    finalItemId?: string[],
    assigned_poc_id?: string,
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    if (category !== undefined) {
      payload.set("charge_master_category", category);
    }

    if (assigned_poc_id) {
      payload.set("staff_id", assigned_poc_id);
    }

    if (charge_type !== undefined) {
      payload.set("charge_type", charge_type);
    }

    if (description !== undefined) {
      payload.set("description", description);
    }

    if (quantity !== undefined) {
      payload.set("quantity", quantity.toString());
    }

    if (amount !== undefined) {
      payload.set("amount", amount.toString());
    }

    if (gst_percentage !== undefined) {
      payload.set("gst_percentage", gst_percentage.toString());
    }

    if (vendor !== undefined && Number(vendor) > 0) {
      payload.set("vendor_id", vendor);
    }

    if (charged_date !== undefined) {
      payload.set("charged_at", charged_date);
    }

    if (
      rental_reservation_id !== undefined &&
      rental_reservation_id.length > 0
    ) {
      payload.set("rental_reservation_id", rental_reservation_id);
    }

    if (note !== undefined) {
      payload.set("note", note);
    }

    if (entity_type !== undefined) {
      payload.set("entity_type", entity_type);
    }

    if (Number(vendor) > 0 && vendor_radio_value !== undefined) {
      payload.set("vendor_payment_type", vendor_radio_value);
    }

    if (Number(vendor) > 0 && vendor_type !== undefined) {
      payload.set("vendor_commission_type", vendor_type);
    }

    if (
      Number(vendor) > 0 &&
      vendor_type === "flat" &&
      vendor_amount !== undefined
    ) {
      payload.set("vendor_lohono_commission", vendor_amount.toString());
    }

    if (
      Number(vendor) > 0 &&
      vendor_type === "percentage" &&
      vendor_percentage !== undefined
    ) {
      payload.set("vendor_lohono_commission", vendor_percentage.toString());
    }

    if (discount_type !== undefined) {
      payload.set("discount_type", discount_type);
    }

    if (discount_type !== undefined && discount_code !== undefined) {
      if (discount_code.length > 0) {
        payload.set("discount_value", discount_code);
      }
    }

    if (
      discount_type !== undefined &&
      discount_value_percentage !== undefined
    ) {
      if (discount_value_percentage > 0) {
        payload.set("discount_value", discount_value_percentage.toString());
      }
    }

    if (discount_type !== undefined && discount_value_flat !== undefined) {
      if (discount_value_flat > 0) {
        payload.set("discount_value", discount_value_flat.toString());
      }
    }

    if (cost_center_radio_value !== undefined) {
      payload.set("selected_cost_center", cost_center_radio_value);
    }

    if (
      cost_center_radio_value === "ivpl" &&
      ivpl_company_budget !== undefined
    ) {
      payload.set("ivpl_budget_amount", ivpl_company_budget.toString());
    }

    if (
      cost_center_radio_value === "ihpl" &&
      ihpl_company_budget !== undefined
    ) {
      payload.set("ihpl_budget_amount", ihpl_company_budget.toString());
    }

    if (
      cost_center_radio_value === "ivpl" &&
      ivpl_reason_for_allocation !== undefined
    ) {
      payload.set("reason_for_allocation", ivpl_reason_for_allocation);
    }

    if (
      cost_center_radio_value === "ihpl" &&
      ihpl_reason_for_allocation !== undefined
    ) {
      payload.set("reason_for_allocation", ihpl_reason_for_allocation);
    }

    if (finalItemId) {
      finalItemId.forEach((each, i) => {
        payload.set(`charge_items_attributes[${i}][item_id]`, each);
      });
    }

    if (document instanceof File) {
      payload.set("documents_attributes[][document_type]", "bill");
      payload.set("documents_attributes[][document_name]", "bill");
      payload.set("documents_attributes[][document_identifier]", "bill");
      payload.set("documents_attributes[][file]", document);
      if (document_id !== undefined) {
        payload.set("documents_attributes[][id]", document_id.toString());
      }
    }

    if (document_id !== undefined && document === null) {
      payload.set("documents_attributes[][id]", document_id.toString());
      payload.set("documents_attributes[][_destroy]", "true");
    }

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/charges/${charge_id}`,
      { error, response } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: response,
    };
  }

  async cancelCharge(
    opportunity_slug: string,
    charge_id: number,
    status: string,
    cancellation_reason:string,
    comments:string,
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    payload.set("status", status);
    payload.set("cancellation_reason", cancellation_reason);
    payload.set("comments", comments);

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/charges/${charge_id}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async removeCharge(
    opportunity_slug: string,
    charge_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/charges/${charge_id}`,
      { error } = await DELETE<void>(url);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async forceBookReservation(
    opportunity_slug: string,
    reservation_slug: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        opportunity_slug: opportunity_slug,
      },
      url = `/api/v2/rental/reservations/${reservation_slug}/force_book`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async removeReservation(
    opportunity_slug: string,
    reservation_slug: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        opportunity_slug: opportunity_slug,
      },
      url = `/api/v2/rental/reservations/${reservation_slug}/remove`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async cancelReservation(
    opportunity_slug: string,
    reservation_slug: string,
    cancellation_reason_id: number,
    credit?: number,
    credit_expire_date?: string,
    refund?: number,
    cancellation_charge?: number,
    cancellation_rule_id?: number,
    cancellation_note?: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        opportunity_slug: opportunity_slug,
        cancellation_reason_id: cancellation_reason_id,
        amount_credited: credit,
        expiry: credit_expire_date,
        amount_refunded: refund,
        cancellation_charges: cancellation_charge,
        cancellation_rule_id: cancellation_rule_id,
        cancellation_note: cancellation_note,
      },
      url = `/api/v2/rental/reservations/${reservation_slug}/cancel`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async generatePI(
    opportunity_slug: string,
    reservation_slug: string,
    invoice_type: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        reservation_slug,
        opportunity_slug,
        invoice_type,
      },
      url = `/api/v2/invoices`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async generateChargesInvoice(
    opportunity_slug: string,
    invoice_type: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        opportunity_slug,
        invoice_type,
      },
      url = `/api/v2/charges_invoices`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async holdReservation(
    opportunity_slug: string,
    reservation_slug: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        opportunity_slug: opportunity_slug,
      },
      url = `/api/v2/rental/reservations/${reservation_slug}/hold`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async noShowReservation(
    opportunity_slug: string,
    reservation_slug: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        opportunity_slug: opportunity_slug,
      },
      url = `/api/v2/rental/reservations/${reservation_slug}/tag_no_show`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async releaseReservation(hold_id: number): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/hold/${hold_id}/release`,
      { error } = await POST<void>(url, null);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async checkInUpdate(
    opportunity_slug: string,
    reservation_slug: string,
    check_in: boolean,
    check_out: boolean,
    adults?: number,
    children?: number,
    staffs?: number,
    pets?: number,
  ): Promise<FetchResult<void>> {
    const payload =
        check_in || check_out
          ? {
              opportunity_slug: opportunity_slug,
              check_out: check_out,
              check_in: check_in,
            }
          : {
              opportunity_slug: opportunity_slug,
              check_in_details: {
                adults: adults,
                children: children,
                staffs: staffs,
                pets: pets,
              },
            },
      url = `/api/v2/rental/reservations/${reservation_slug}/check_in_detail`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getTripReservations(
    categories?: string,
    location?: string,
    properties?: string[],
    checkin_date?: string,
    checkout_date?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<TripReservationItem[]>> {
    const qp = new URLSearchParams();
    if (categories) {
      qp.set("type", categories);
    }
    if (location) {
      qp.set("location_id", location);
    }
    if (properties) {
      properties.forEach((property) => qp.append("property_ids[]", property));
    }
    if (checkin_date) {
      qp.set("check_in", checkin_date);
    }
    if (checkout_date) {
      qp.set("check_out", checkout_date);
    }
    if (search) {
      qp.set("query", search);
    }
    if (page) {
      qp.set("page", page);
    }
    if (page_size) {
      qp.set("per_page", page_size);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/reservations";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<TripReservationResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.reservations.map((each) => {
      const {
          id,
          slug,
          check_in,
          check_out,
          adults,
          children,
          opportunity_slug,
          property_name,
          property_address,
          name,
          mobile,
          tier_name,
        } = each,
        formatted: TripReservationItem = {
          id: id || -1,
          reservation_slug: slug || "",
          checkin_date: check_in || "",
          checkout_date: check_out || "",
          adult_count: adults || 0,
          child_count: children || 0,
          opportunity_slug: opportunity_slug || "",
          property_name: property_name || "",
          property_address: property_address || "",
          customer_name: name || "",
          phone_no: mobile || "",
          tier_name: tier_name || "",
        };

      return formatted;
    });

    return {
      error: null,
      response: formatted,
      paginate: response.paginate,
    };
  }

  async refundBadStay(
    opportunity_slug: string,
    team_accountable: string,
    bad_stay_reason: string,
    refund_amount?: number,
    credit_amount?: number,
    credit_expiry?: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        amount_credited: credit_amount,
        amount_refunded: refund_amount,
        expiry: credit_expiry,
        account: team_accountable,
        reason: bad_stay_reason,
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/refunds/bad_stay`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async upgradeBadStay(
    opportunity_slug: string,
    team_accountable: string,
    bad_stay_reason: string,
    amount_difference: number,
  ): Promise<FetchResult<void>> {
    const payload = {
        account: team_accountable,
        reason: bad_stay_reason,
        amount: amount_difference,
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/payments/bad_stay`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getRequestServices(
    start_date?: string,
    end_date?: string,
    properties?: string[],
    chargeType?: string[],
    status?: string,
    location?: string[],
    assigned_gre?: string,
    assigned_e_and_e?: string,
    assigned_poc?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<RentalTripRequestItem[]>> {
    const qp = new URLSearchParams();

    if (start_date) {
      qp.set("delivery_date_start", start_date.toString());
    }

    if (end_date) {
      qp.set("delivery_date_end", end_date.toString());
    }

    if (properties?.length) {
      properties.forEach((each) => qp.append("rental_property_id[]", each));
    }

    if (location?.length) {
      location.forEach((each) => qp.append("rental_location_id[]", each));
    }

    if (chargeType?.length) {
      chargeType.forEach((each) => qp.append("charge_type[]", each));
    }

    if (status) {
      qp.set("status[]", status.toString());
    }

    if (assigned_gre) {
      qp.set("assigned_to_id", assigned_gre.toString());
    }

    if (assigned_e_and_e) {
      qp.set("requested_by_id", assigned_e_and_e.toString());
    }

    if (assigned_poc) {
      qp.set("staff_id", assigned_poc.toString());
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    if (search) {
      qp.set("query", search);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/rental/charges/list";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<ChargeListingResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.charges.map((each) => {
      const {
          id,
          opportunity_details: { slug, name },
          property_name,
          charge_type,
          status,
          delivery_time,
          description,
          details,
          digital_concierge,
          gre_poc,
          ene_poc,
          check_in,
          check_out,
          assigned_to,
          requested_by,
        } = each,
        formatted: RentalTripRequestItem = {
          id: id || 0,
          slug: slug || "",
          name: name || "",
          status: status || "",
          charge_type: charge_type || "",
          delivery_time: delivery_time || "",
          property_name: property_name || "",
          description: description || "",
          details: details || "",
          digital_concierge: digital_concierge || false,
          gre_name: gre_poc || "",
          e_and_e_name: ene_poc || "",
          check_in: check_in || "",
          check_out: check_out || "",
          assigned_to: assigned_to || "",
          requested_by: requested_by || "",
        };

      return formatted;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  async getRequestService(
    opportunity_slug: string,
  ): Promise<FetchResult<RentalTripRequests>> {
    const url = `/api/v2/rental/charges/list?opportunity_id=${opportunity_slug}`,
      { error, response } = await GET<RequestResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const availed_charges: Charge[] = response.charges.availed_charges.map(
      (each) => {
        const {
            id,
            description,
            details,
            charge_master_category,
            charge_type,
            diet_preference_id,
            diet_preference_label,
            amount,
            gst_percentage,
            vendor_id,
            vendor_commission_type,
            vendor_lohono_commission,
            vendor_payment_type,
            charged_at,
            quantity,
            payment_status,
            payment_mode,
            entity_type,
            rental_property_id,
            rental_reservation_id,
            note,
            staff_id,
            items,
            food,
            breakdown: {
              base_amount = 0,
              convenience_fee = 0,
              tax_on_base_amount = 0,
              tax_on_convenience_fee = 0,
              currency_symbol = "",
              discount = 0,
              delivery_charges = 0,
            } = {
              base_amount: 0,
              convenience_fee: 0,
              tax_on_base_amount: 0,
              tax_on_convenience_fee: 0,
              currency_symbol: "",
              discount: 0,
              delivery_charges: 0,
            },
            documents,
            show_cta,
            discount_details: {
              discount_type = "",
              discount_value = "",
              ihpl_budget_amount = "",
              ivpl_budget_amount = "",
              reason_for_allocation = "",
              selected_cost_center = "",
              additional_discount_type = "",
              additional_discount_value = "",
            } = {
              discount_type: "",
              discount_value: "",
              ihpl_budget_amount: "",
              ivpl_budget_amount: "",
              reason_for_allocation: "",
              selected_cost_center: "",
              additional_discount_type: "",
              additional_discount_value: "",
            },
          } = each,
          document_id = documents.length ? documents[0].id : undefined,
          document = documents.length ? documents[0].link : undefined,
          airport_item = items.length ? items[0].details : undefined,
          meal_plan_item = items.length ? items[0].details : undefined,
          breakfast_item = items.map((each: any) => each.details.id),
          charge: Charge = {
            id: id || -1,
            description: description || "",
            details: details || "",
            category: charge_master_category || "",
            charge_type: charge_type || "",
            diet_preference_id: diet_preference_id || "",
            diet_preference_label: diet_preference_label || "",
            amount: amount || 0,
            quantity: quantity || 0,
            gst_percentage: gst_percentage || "0",
            vendor: vendor_id || -1,
            vendor_commission_type: vendor_commission_type || "",
            vendor_lohono_commission: vendor_lohono_commission || "",
            vendor_payment_type: vendor_payment_type || "",
            charged_date: charged_at || "",
            paid: payment_status || "",
            payment_mode: payment_mode || "",
            rental_property_id: rental_property_id || "",
            rental_reservation_id: rental_reservation_id || "",
            entity_type: entity_type || "",
            note: note || "",
            food: food || false,
            assigned_poc: staff_id || "",
            breakdown: {
              average_base_rate: base_amount || 0,
              gross_base_rate: base_amount || 0,
              taxes: tax_on_base_amount || 0,
              convenience_fee:
                (convenience_fee || 0) + (tax_on_convenience_fee || 0),
              deposit: 0,
              commission: 0,
              discount: discount || 0,
              currency_symbol: currency_symbol || "",
              ihpl_budget_amount: ihpl_budget_amount || "",
              ivpl_budget_amount: ivpl_budget_amount || "",
              delivery_charges: delivery_charges || 0,
            },
            document_id: document_id || undefined,
            document: document || "",
            show_cta: show_cta,
            discount_type: discount_type || "",
            discount_value: discount_value || "",
            ihpl_budget_amount: ihpl_budget_amount || "",
            ivpl_budget_amount: ivpl_budget_amount || "",
            reason_for_allocation: reason_for_allocation || "",
            selected_cost_center: selected_cost_center || "",
            additional_discount_type: additional_discount_type || "",
            additional_discount_value: additional_discount_value || "",
            airport_item: airport_item || undefined,
            breakfast_item: breakfast_item || [],
            meal_plan_item: meal_plan_item || undefined,
          };

        return charge;
      },
    );

    const non_availed_charges: Charge[] =
      response.charges.non_availed_charges.map((each) => {
        const {
            id,
            description,
            details,
            charge_master_category,
            charge_type,
            diet_preference_id,
            diet_preference_label,
            amount,
            gst_percentage,
            vendor_id,
            vendor_commission_type,
            vendor_lohono_commission,
            vendor_payment_type,
            charged_at,
            quantity,
            payment_status,
            payment_mode,
            rental_property_id,
            rental_reservation_id,
            note,
            items,
            food,
            staff_id,
            breakdown: {
              base_amount = 0,
              convenience_fee = 0,
              tax_on_base_amount = 0,
              tax_on_convenience_fee = 0,
              currency_symbol = "",
              discount = 0,
              delivery_charges = 0,
            } = {
              base_amount: 0,
              convenience_fee: 0,
              tax_on_base_amount: 0,
              tax_on_convenience_fee: 0,
              currency_symbol: "",
              discount: 0,
              delivery_charges: 0,
            },
            documents,
            show_cta,
            status,
            delivery_time,
            discount_details: {
              discount_type = "",
              discount_value = "",
              ihpl_budget_amount = "",
              ivpl_budget_amount = "",
              reason_for_allocation = "",
              selected_cost_center = "",
              additional_discount_type = "",
              additional_discount_value = "",
            } = {
              discount_type: "",
              discount_value: "",
              ihpl_budget_amount: "",
              ivpl_budget_amount: "",
              reason_for_allocation: "",
              selected_cost_center: "",
              additional_discount_type: "",
              additional_discount_value: "",
            },
          } = each,
          document_id = documents.length ? documents[0].id : undefined,
          document = documents.length ? documents[0].link : undefined,
          airport_item = items.length ? items[0].details : undefined,
          meal_plan_item = items.length ? items[0].details : undefined,
          breakfast_item = items.map((each: any) => each.details.id),
          charge: Charge = {
            id: id || -1,
            description: description || "",
            details: details || "",
            category: charge_master_category || "",
            charge_type: charge_type || "",
            diet_preference_id: diet_preference_id || "",
            diet_preference_label: diet_preference_label || "",
            amount: amount || 0,
            quantity: quantity || 0,
            gst_percentage: gst_percentage || "0",
            vendor: vendor_id || -1,
            vendor_commission_type: vendor_commission_type || "",
            vendor_lohono_commission: vendor_lohono_commission || "",
            vendor_payment_type: vendor_payment_type || "",
            charged_date: charged_at || "",
            paid: payment_status || "",
            payment_mode: payment_mode || "",
            rental_property_id: rental_property_id || "",
            rental_reservation_id: rental_reservation_id || "",
            note: note || "",
            food: food || false,
            assigned_poc: staff_id || "",
            breakdown: {
              average_base_rate: base_amount || 0,
              gross_base_rate: base_amount || 0,
              taxes: tax_on_base_amount || 0,
              convenience_fee:
                (convenience_fee || 0) + (tax_on_convenience_fee || 0),
              deposit: 0,
              commission: 0,
              discount: discount || 0,
              currency_symbol: currency_symbol || "",
              ihpl_budget_amount: ihpl_budget_amount || "",
              ivpl_budget_amount: ivpl_budget_amount || "",
              delivery_charges: delivery_charges || 0,
            },
            document_id: document_id || undefined,
            document: document || "",
            show_cta: show_cta,
            status: status,
            delivery_time: delivery_time,
            discount_type: discount_type || "",
            discount_value: discount_value || "",
            ihpl_budget_amount: ihpl_budget_amount || "",
            ivpl_budget_amount: ivpl_budget_amount || "",
            reason_for_allocation: reason_for_allocation || "",
            selected_cost_center: selected_cost_center || "",
            additional_discount_type: additional_discount_type || "",
            additional_discount_value: additional_discount_value || "",
            airport_item: airport_item || undefined,
            breakfast_item: breakfast_item || [],
            meal_plan_item: meal_plan_item || undefined,
          };

        return charge;
      });

    return {
      error: null,
      response: {
        charges: {
          availed_charges: [...availed_charges],
          non_availed_charges: [...non_availed_charges],
          total_charges_amount: response.charges.total_charges_amount,
        },
      },
    };
  }

  async addRequest(
    opportunity_slug: string,
    category: string,
    charge_type: string,
    description: string,
    quantity: number,
    amount: string,
    gst_percentage: number,
    vendor: string,
    charged_date: string,
    rental_reservation_id: string,
    note: string,
    assigned_poc?: string,
    vendor_radio_value?: string,
    vendor_type?: string,
    vendor_amount?: number,
    vendor_percentage?: number,
    document?: File | string,
    status?: string,
    discount_type?: string,
    discount_code?: string,
    discount_value_percentage?: number,
    discount_value_flat?: number,
    cost_center_radio_value?: string,
    ivpl_company_budget?: number,
    ihpl_company_budget?: number,
    ivpl_reason_for_allocation?: string,
    ihpl_reason_for_allocation?: string,
    finalItemId?: string[],
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    payload.set("charge_master_category", category);
    payload.set("charge_type", charge_type);
    payload.set("description", description);
    payload.set("quantity", quantity.toString());
    payload.set("amount", amount.toString());
    payload.set("gst_percentage", gst_percentage.toString());
    payload.set("delivery_time", charged_date);
    payload.set("note", note);

    if (assigned_poc !== undefined && Number(assigned_poc) > 0) {
      payload.set("staff_id", assigned_poc);
    }

    if (status !== undefined && status.length > 0) {
      payload.set("status", status);
    }

    if (rental_reservation_id !== undefined) {
      payload.set("rental_reservation_id", rental_reservation_id);
    }

    if (vendor !== undefined && Number(vendor) > 0) {
      payload.set("vendor_id", vendor);
    }

    if (Number(vendor) > 0 && vendor_radio_value) {
      payload.set("vendor_payment_type", vendor_radio_value);
    }

    if (Number(vendor) > 0 && vendor_type !== undefined) {
      payload.set("vendor_commission_type", vendor_type);
    }

    if (
      Number(vendor) > 0 &&
      vendor_type === "flat" &&
      vendor_amount !== undefined
    ) {
      payload.set("vendor_lohono_commission", vendor_amount.toString());
    }

    if (
      Number(vendor) > 0 &&
      vendor_type === "percentage" &&
      vendor_percentage !== undefined
    ) {
      payload.set("vendor_lohono_commission", vendor_percentage.toString());
    }

    if (discount_type !== undefined) {
      payload.set("discount_type", discount_type);
    }

    if (discount_type !== undefined && discount_code !== undefined) {
      if (discount_code.length > 0) {
        payload.set("discount_value", discount_code);
      }
    }

    if (
      discount_type !== undefined &&
      discount_value_percentage !== undefined
    ) {
      if (discount_value_percentage > 0) {
        payload.set("discount_value", discount_value_percentage.toString());
      }
    }

    if (discount_type !== undefined && discount_value_flat !== undefined) {
      if (discount_value_flat > 0) {
        payload.set("discount_value", discount_value_flat.toString());
      }
    }

    if (cost_center_radio_value !== undefined) {
      payload.set("selected_cost_center", cost_center_radio_value);
    }

    if (
      cost_center_radio_value === "ivpl" &&
      ivpl_company_budget !== undefined
    ) {
      payload.set("ivpl_budget_amount", ivpl_company_budget.toString());
    }

    if (
      cost_center_radio_value === "ihpl" &&
      ihpl_company_budget !== undefined
    ) {
      payload.set("ihpl_budget_amount", ihpl_company_budget.toString());
    }

    if (
      cost_center_radio_value === "ivpl" &&
      ivpl_reason_for_allocation !== undefined
    ) {
      payload.set("reason_for_allocation", ivpl_reason_for_allocation);
    }

    if (
      cost_center_radio_value === "ihpl" &&
      ihpl_reason_for_allocation !== undefined
    ) {
      payload.set("reason_for_allocation", ihpl_reason_for_allocation);
    }

    if (document instanceof File) {
      payload.set("documents_attributes[][document_type]", "bill");
      payload.set("documents_attributes[][document_name]", "bill");
      payload.set("documents_attributes[][document_identifier]", "bill");
      payload.set("documents_attributes[][file]", document);
    }

    if (finalItemId !== undefined && finalItemId.length > 0) {
      finalItemId.forEach((each, i) => {
        payload.set(`charge_items_attributes[${i}][item_id]`, each);
      });
    }

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/charges`,
      { error, response } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: response,
    };
  }

  async updateRequest(
    opportunity_slug: string,
    category: string,
    charge_id: number,
    charge_type?: string,
    description?: string,
    quantity?: number,
    amount?: number,
    gst_percentage?: string,
    vendor?: string,
    chargedDate?: string,
    charged_at?: string,
    assigned_poc?: string,
    rental_reservation_id?: string,
    note?: string,
    entity_type?: string,
    vendor_radio_value?: string,
    vendor_type?: string,
    vendor_amount?: number,
    vendor_percentage?: number,
    document_id?: number,
    document?: File | string,
    status?: string,
    discount_type?: string,
    discount_code?: string,
    discount_value_percentage?: number,
    discount_value_flat?: number,
    cost_center_radio_value?: string,
    ivpl_company_budget?: number,
    ihpl_company_budget?: number,
    ivpl_reason_for_allocation?: string,
    ihpl_reason_for_allocation?: string,
    finalItemId?: string[],
    cancellation_reason?: string,
    other_reason?: string,
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    if (category !== undefined) {
      payload.set("charge_master_category", category);
    }

    if (charge_type !== undefined) {
      payload.set("charge_type", charge_type);
    }

    if (description !== undefined) {
      payload.set("description", description);
    }

    if (quantity !== undefined) {
      payload.set("quantity", quantity.toString());
    }

    if (amount !== undefined) {
      payload.set("amount", amount.toString());
    }

    if (gst_percentage !== undefined) {
      payload.set("gst_percentage", gst_percentage.toString());
    }

    if (vendor !== undefined && Number(vendor) > 0) {
      payload.set("vendor_id", vendor);
    }

    if (charged_at !== undefined) {
      payload.set("charged_at", charged_at);
    }

    if (assigned_poc !== undefined && Number(assigned_poc) > 0) {
      payload.set("staff_id", assigned_poc);
    }

    if (rental_reservation_id !== undefined) {
      payload.set("rental_reservation_id", rental_reservation_id.toString());
    }

    if (note !== undefined) {
      payload.set("note", note);
    }

    if (chargedDate !== undefined) {
      payload.set("delivery_time", chargedDate);
    }

    if (status !== undefined && status.length > 0) {
      payload.set("status", status);
    }

    if (cancellation_reason !== undefined) {
      payload.set("cancellation_reason", cancellation_reason);
    }

    if (cancellation_reason === "others" && other_reason !== undefined) {
      payload.set("comments", other_reason);
    }

    if (entity_type !== undefined) {
      payload.set("entity_type", entity_type);
    }

    if (Number(vendor) > 0 && vendor_radio_value !== undefined) {
      payload.set("vendor_payment_type", vendor_radio_value);
    }

    if (Number(vendor) > 0 && vendor_type !== undefined) {
      payload.set("vendor_commission_type", vendor_type);
    }

    if (
      Number(vendor) > 0 &&
      vendor_type === "flat" &&
      vendor_amount !== undefined
    ) {
      payload.set("vendor_lohono_commission", vendor_amount.toString());
    }

    if (
      Number(vendor) > 0 &&
      vendor_type === "percentage" &&
      vendor_percentage !== undefined
    ) {
      payload.set("vendor_lohono_commission", vendor_percentage.toString());
    }

    if (discount_type !== undefined) {
      payload.set("discount_type", discount_type);
    }

    if (discount_type !== undefined && discount_code !== undefined) {
      if (discount_code.length > 0) {
        payload.set("discount_value", discount_code);
      }
    }

    if (
      discount_type !== undefined &&
      discount_value_percentage !== undefined
    ) {
      if (discount_value_percentage > 0) {
        payload.set("discount_value", discount_value_percentage.toString());
      }
    }

    if (discount_type !== undefined && discount_value_flat !== undefined) {
      if (discount_value_flat > 0) {
        payload.set("discount_value", discount_value_flat.toString());
      }
    }

    if (cost_center_radio_value !== undefined) {
      payload.set("selected_cost_center", cost_center_radio_value);
    }

    if (
      cost_center_radio_value === "ivpl" &&
      ivpl_company_budget !== undefined
    ) {
      payload.set("ivpl_budget_amount", ivpl_company_budget.toString());
    }

    if (
      cost_center_radio_value === "ihpl" &&
      ihpl_company_budget !== undefined
    ) {
      payload.set("ihpl_budget_amount", ihpl_company_budget.toString());
    }

    if (
      cost_center_radio_value === "ivpl" &&
      ivpl_reason_for_allocation !== undefined
    ) {
      payload.set("reason_for_allocation", ivpl_reason_for_allocation);
    }

    if (
      cost_center_radio_value === "ihpl" &&
      ihpl_reason_for_allocation !== undefined
    ) {
      payload.set("reason_for_allocation", ihpl_reason_for_allocation);
    }

    if (finalItemId) {
      finalItemId.forEach((each, i) => {
        payload.set(`charge_items_attributes[${i}][item_id]`, each);
      });
    }

    if (document instanceof File) {
      payload.set("documents_attributes[][document_type]", "bill");
      payload.set("documents_attributes[][document_name]", "bill");
      payload.set("documents_attributes[][document_identifier]", "bill");
      payload.set("documents_attributes[][file]", document);
      if (document_id !== undefined) {
        payload.set("documents_attributes[][id]", document_id.toString());
      }
    }

    if (document_id !== undefined && document === null) {
      payload.set("documents_attributes[][id]", document_id.toString());
      payload.set("documents_attributes[][_destroy]", "true");
    }

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/charges/${charge_id}`,
      { error, response } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: response,
    };
  }

  async getForceBookApproval(
    opportunity_slug: string,
    approvalType: string,
  ): Promise<FetchResult<void>> {
    const payload = { approval_type: approvalType },
      url = `/api/v2/rental/reservations/${opportunity_slug}/approvals`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getCancellationReasons(
    mapping_type: string,
  ): Promise<FetchResult<RentalCancellationReasons>> {
    const url = `/api/v2/rental/cancellation_reasons?mapping_type=${mapping_type}`,
      { error, response } = await GET<CancellationReasonsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: response,
    };
  }

  async getCancellationOptions(
    opportunitySlug: string,
    reservationSlug: string,
    reasonId: number,
  ): Promise<FetchResult<RentalCancellationOption>> {
    const url = `/api/v2/rental/reservations/${reservationSlug}/cancellation_options?reason_id=${reasonId}&opportunity_slug=${opportunitySlug}`,
      { error, response } = await GET<CancellationOptionResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: response,
    };
  }

  async getVendorDetails(
    vendorId: string,
    viewType: string,
  ): Promise<FetchResult<RentalVendorDetails>> {
    const url = `/api/v2/vendors/${vendorId}?view=${viewType}`,
      { error, response } = await GET<vendorDetailResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: RentalVendorDetails = {
      commission_type: response.vendor.commission_type || "",
      lohono_commission: response.vendor.lohono_commission || "",
    };

    return {
      error: null,
      response: formatted,
    };
  }

  async getCategoryBasedChargeList(
    catergoryType: string,
    reservationId?: string,
  ): Promise<FetchResult<RentalCategoryChargeList>> {
    const url = `/api/v2/rental/charges/get_charge_types?category=${catergoryType}&reservation_id=${reservationId}`,
      { error, response } = await GET<chargeListResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: RentalCategoryChargeList = {
      charge_types: response.charge_types,
    };

    return {
      error: null,
      response: formatted,
    };
  }

  async getChargeMaster(
    opportunity_slug: string,
    reservation_slug: string,
  ): Promise<FetchResult<RentalChargeMaster>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/charges/masters?reservation_id=${reservation_slug}`,
      { error, response } = await GET<chargeMasterResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const { airport_trasfers, breakfast_menus, fixed_menus } = response;
    const formatted: RentalChargeMaster = {
      airport_transfer_locations:
        airport_trasfers.airport_transfer_locations || [],
      airports: airport_trasfers.airports || [],
      cars: airport_trasfers.cars || [],
      transfer_types: airport_trasfers.transfer_types,
      breakfast_menus: breakfast_menus[0]?.menu_items[0]?.sub_categories || [],
      fixed_menus: fixed_menus || [],
    };

    return {
      error: null,
      response: formatted,
    };
  }

  async getRequestServiceStats(
    chargeType?: string[],
    assigned_gre?: string,
    assigned_e_and_e?: string,
    assigned_poc?: string,
    properties?: string[],
    search?: string,
    // start_date?: string,
    // end_date?: string,
    // status?: string,
    // location?: string[],
  ): Promise<FetchResult<RequestServiceStats>> {
    const qp = new URLSearchParams();

    // if (start_date) {
    //   qp.set("delivery_date_start", start_date.toString());
    // }

    // if (end_date) {
    //   qp.set("delivery_date_end", end_date.toString());
    // }

    if (properties?.length) {
      properties.forEach((each) => qp.append("rental_property_id[]", each));
    }

    // if (location?.length) {
    //   location.forEach((each) => qp.append("rental_location_id[]", each));
    // }

    if (chargeType?.length) {
      chargeType.forEach((each) => qp.append("charge_type[]", each));
    }

    // if (status) {
    //   qp.set("status[]", status.toString());
    // }

    if (assigned_gre) {
      qp.set("assigned_to_id", assigned_gre.toString());
    }

    if (assigned_e_and_e) {
      qp.set("requested_by_id", assigned_e_and_e.toString());
    }

    if (assigned_poc) {
      qp.set("staff_id", assigned_poc.toString());
    }

    if (search) {
      qp.set("query", search);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/rental/charges/stats";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<any>(url);

    // const url = `/api/v2/rental/charges/stats`,
    // { error, response } = await GET<any>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("unknown error"),
        response: null,
      };
    }

    const { requested, confirmed, availed, over_due, cancelled } =
        response.stats,
      formatted: RequestServiceStats = {
        requested: requested,
        confirmed: confirmed,
        availed: availed,
        over_due: over_due,
        cancelled: cancelled,
      };

    return {
      error: null,
      response: formatted,
    };
  }

  async getRequestServiceSummary(
    opportunity_slug: string,
    charge_id: number,
  ): Promise<FetchResult<RequestServiceSummary>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/charges/${charge_id}`,
      { error, response } = await GET<chargeSummaryResponse>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("unknown error"),
        response: null,
      };
    }

    const {
        id,
        description,
        details,
        charge_master_category,
        charge_type,
        fixed_menu,
        diet_preference_id,
        diet_preference_label,
        amount,
        gst_percentage,
        vendor_id,
        vendor_name,
        vendor_commission_type,
        vendor_lohono_commission,
        vendor_payment_type,
        charged_at,
        quantity,
        payment_status,
        payment_mode,
        entity_type,
        rental_property_id,
        property_name,
        rental_reservation_id,
        note,
        status,
        adults,
        children,
        country_code,
        mobile,
        check_in,
        check_out,
        items,
        menu_name,
        food_items,
        delivery_time,
        food,
        staff_id,
        breakdown: {
          base_amount = 0,
          convenience_fee = 0,
          tax_on_base_amount = 0,
          tax_on_convenience_fee = 0,
          currency_symbol = "",
          discount = 0,
          delivery_charges = 0,
          delivery_charge_taxes = 0,
        } = {
          base_amount: 0,
          convenience_fee: 0,
          tax_on_base_amount: 0,
          tax_on_convenience_fee: 0,
          currency_symbol: "",
          discount: 0,
        },
        documents,
        show_cta,
        discount_details: {
          discount_type = "",
          discount_value = "",
          ihpl_budget_amount = "",
          ivpl_budget_amount = "",
          reason_for_allocation = "",
          selected_cost_center = "",
          additional_discount_type = "",
          additional_discount_value = "",
        } = {
          discount_type: "",
          discount_value: "",
          ihpl_budget_amount: "",
          ivpl_budget_amount: "",
          reason_for_allocation: "",
          selected_cost_center: "",
          additional_discount_type: "",
          additional_discount_value: "",
        },
        opportunity_details: { name, slug },
      } = response.charge,
      document_id = documents.length ? documents[0].id : undefined,
      document = documents.length ? documents[0].link : undefined,
      airport_item = items.length ? items[0].details : undefined,
      meal_plan_item = items.length ? items[0].details : undefined,
      breakfast_item = items.map((each: any) => each.details.id),
      formatted: RequestServiceSummary = {
        id: id || -1,
        opportunity_name: name || "",
        opportunity_slug: slug || "",
        description: description || "",
        details: details || "",
        category: charge_master_category || "",
        charge_type: charge_type || "",
        fixed_menu: fixed_menu || false,
        diet_preference_id: diet_preference_id || "",
        diet_preference_label: diet_preference_label || "",
        delivery_time: delivery_time || "",
        amount: amount || 0,
        quantity: quantity || 0,
        gst_percentage: gst_percentage || "0",
        assigned_poc: staff_id || "",
        vendor: vendor_id || -1,
        vendor_name: vendor_name || "",
        vendor_commission_type: vendor_commission_type || "",
        vendor_lohono_commission: vendor_lohono_commission || "",
        vendor_payment_type: vendor_payment_type || "",
        charged_date: charged_at || "",
        paid: payment_status || "",
        payment_mode: payment_mode || "",
        property_name: property_name || "",
        rental_property_id: rental_property_id || "",
        rental_reservation_id: rental_reservation_id || "",
        entity_type: entity_type || "",
        note: note || "",
        status: status || "",
        adults: adults || 0,
        children: children || 0,
        country_code: country_code || "",
        mobile: mobile || "",
        check_in_date: check_in || "",
        check_out_date: check_out || "",
        food: food || false,
        breakdown: {
          average_base_rate: base_amount || 0,
          gross_base_rate: base_amount || 0,
          taxes: tax_on_base_amount || 0,
          convenience_fee:
            (convenience_fee || 0) + (tax_on_convenience_fee || 0),
          deposit: 0,
          commission: 0,
          discount: discount || 0,
          currency_symbol: currency_symbol || "",
          ihpl_budget_amount: ihpl_budget_amount || "",
          ivpl_budget_amount: ivpl_budget_amount || "",
        },
        document_id: document_id || undefined,
        document: document || "",
        show_cta: show_cta,
        discount_type: discount_type || "",
        discount_value: discount_value || "",
        ihpl_budget_amount: ihpl_budget_amount || "",
        ivpl_budget_amount: ivpl_budget_amount || "",
        reason_for_allocation: reason_for_allocation || "",
        selected_cost_center: selected_cost_center || "",
        additional_discount_type: additional_discount_type || "",
        additional_discount_value: additional_discount_value || "",
        airport_item: airport_item || undefined,
        breakfast_item: breakfast_item || [],
        meal_plan_item: meal_plan_item || undefined,
        food_items: food_items || [],
        menu_name: menu_name || "",
        delivery_charges: delivery_charges || 0,
        delivery_charge_taxes: delivery_charge_taxes || 0,
      };
    return {
      error: null,
      response: formatted,
    };
  }

  async getRequestVeiwHistory(
    opportunity_slug: string,
    charge_id: number,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<VersionHistoryResponse>> {

    const qp = new URLSearchParams();

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    const marshalled = qp.toString();
    
    let url = `/api/v2/rental/opportunities/${opportunity_slug}/charges/${charge_id}/history`;

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }
  
    const { error, response } = await GET<versionsResponse>(url);
  
    if (error) {
      return {
        error: error,
        response: null,
      };
    }
  
    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }
  
    // Extract and format the response data
    const formatted: VersionHistoryResponse = {
      created_by: response.versions.created_by || "",
      created_at: response.versions.created_at || "",
      // versions: response.versions.versions.map((version : VersionDetails) => ({
      //   charge_type: {
      //     previous: version.charge_type?.previous || null,
      //     current: version.charge_type?.current || null,
      //     display_name: version.charge_type?.display_name || "",
      //     display_type: version.charge_type?.display_type || "",
      //   },
      //   description: {
      //     previous: version.description?.previous || null,
      //     current: version.description?.current || null,
      //     display_name: version.description?.display_name || "",
      //     display_type: version.description?.display_type || "",
      //   },
      //   base_amount: {
      //     previous: version.base_amount?.previous || null,
      //     current: version.base_amount?.current || null,
      //     display_name: version.base_amount?.display_name || "",
      //     display_type: version.base_amount?.display_type || "",
      //   },
      //   staff_id: {
      //     previous: version.staff_id?.previous || null,
      //     current: version.staff_id?.current || null,
      //     display_name: version.staff_id?.display_name || "",
      //     display_type: version.staff_id?.display_type || "",
      //   },
      //   vendor_lohono_commission: {
      //     previous: version.vendor_lohono_commission?.previous || null,
      //     current: version.vendor_lohono_commission?.current || null,
      //     display_name: version.vendor_lohono_commission?.display_name || "",
      //     display_type: version.vendor_lohono_commission?.display_type || "",
      //   },
      //   updated_by: version.updated_by,
      //   updated_at: version.updated_at,
      // })),
      versions: response.versions.versions || [],
    };
  
    return {
      error: null,
      response: formatted,
      paginate: response.paginate,
    };
  }

  async getFoodOrderMenuList(
    opprtunitySlug: string,
    reservationId: string,
    chargeType: string,
    menuId?: string,
    foodType?: string,
  ): Promise<FetchResult<FoodOrderMenu[]>> {
    const qp = new URLSearchParams();
    if (reservationId !== undefined) {
      qp.set("rental_reservation_id", reservationId);
    }

    if (chargeType !== undefined) {
      qp.set("charge_type", chargeType);
    }

    if (menuId !== undefined) {
      qp.set("menu_id", menuId);
    }

    if (foodType !== undefined) {
      qp.set("diet_type", foodType);
    }

    const marshalled = qp.toString();

    let url = `/api/v2/rental/opportunities/${opprtunitySlug}/food`;

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<foodOrderMenuListResponse>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("unknown error"),
        response: null,
      };
    }

    const formatted = response.food_menus.map((each) => {
      const {
          adult_price,
          child_price,
          complimentary,
          diet_type,
          menu_items,
          menu_type,
          name,
          value,
          fixed_menu,
          charge_type,
          veg,
          adult_meals,
          child_meals,
        } = each,
        formatted: FoodOrderMenu = {
          adult_price: adult_price || 0,
          child_price: child_price || 0,
          complimentary: complimentary || false,
          fixed_menu: fixed_menu || false,
          charge_type: charge_type,
          diet_type: diet_type || "",
          menu_type: menu_type || "",
          name: name || "",
          value: value,
          menu_items: menu_items || [],
          veg: veg,
          adult_meals: adult_meals || [],
          child_meals: child_meals || [],
        };

      return formatted;
    });

    return {
      error: error,
      response: formatted,
    };
  }

  async updateFoodOrderRequest(
    payload: foodItemsPayload,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${payload.opportunity_slug}/food/bulk_update`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default RentalTripPaymentService;

type PaymentResponse = {
  payment: any[];
  outstanding_amount: number;
  credits_available: number;
  net_paid_amount: number;
  total_paid_amount: number;
  total_reservations_amount: number;
  total_charges_amount: number;
  is_international: boolean;
  loyalty_points_available: number;
};

type ReservationsResponse = {
  reservations: any[];
  charges: any[];
  total: number;
  total_paid_amount: number;
  net_paid_amount: number;
  total_reservations_amount: number;
  total_charges_amount: number;
  tax_invoice_charges_created: boolean;
  reservation_currency: string;
  total_amount_breakup: {
    agent_cutting_commission: boolean;
    base_payable: number;
    base_payable_breakdown: PriceBreakdown;
    base_payable_message: string;
    total_payable: number;
    total_payable_breakdown: PriceBreakdown;
    total_payable_message: number;
  };
};

type RequestResponse = {
  charges: {
    availed_charges: any[];
    non_availed_charges: any[];
    total_charges_amount: number;
  };
};

type TripReservationResponse = {
  reservations: any[];
  paginate: Pagination;
};

type ChargeListingResponse = {
  charges: any[];
  paginate: Pagination;
};

type CancellationReasonsResponse = {
  cancellation_reasons: any[];
};

type CancellationOptionResponse = {
  cancellation_options: any;
};

type vendorDetailResponse = {
  vendor: any;
};

type chargeListResponse = {
  charge_types: any[];
};

type chargeMasterResponse = {
  airport_trasfers: any;
  ala_carte_menus: any[];
  breakfast_menus: any[];
  fixed_menus: any[];
};

type chargeSummaryResponse = {
  charge: any;
};

type foodOrderMenuListResponse = {
  food_menus: any[];
};

type versionsResponse = {
  versions: any;
  paginate: any;
};
